import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AnimateButton from 'components/@extended/AnimateButton'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ComponentType } from 'types/components'
import { Component } from 'types/components'
import { KeyedObject } from 'types/root'
import type { JSX } from 'react'
interface ColumnProps {
  id: string | null
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: Component) => string | boolean | JSX.Element
}

interface ComponentsTableProps {
  components: Component[]
  page: number
  rowsPerPage: number
  sort: string | null
  order: string
  handleChangePage: (event: unknown, newPage: number) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSort: (property: string) => void
  totalItems: number
  showActionsColumn?: boolean
}

const ComponentsTable: React.FC<ComponentsTableProps> = ({
  components,
  page,
  rowsPerPage,
  sort,
  order,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSort,
  totalItems,
  showActionsColumn = true,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const columns: ColumnProps[] = [
    { id: 'label', label: 'Name', minWidth: 170 },
    {
      id: 'organizationName',
      label: 'Team',
      minWidth: 200,
      format: (value: any, row: any) => row.organizationName,
    },
    {
      id: 'isOrganizationLevel',
      label: 'Type',
      minWidth: 85,
      format: (value: any, row: Component) => {
        return value ? 'Team' : 'Individual'
      },
    },
    {
      id: 'componentType',
      label: 'Format',
      minWidth: 85,
      format: (value: any, row: Component) => {
        switch (value) {
          case ComponentType.COMPONENT_TYPE_INTEGER:
            return 'Integer'
          case ComponentType.COMPONENT_TYPE_PERCENTAGE:
            return 'Percent'
          case ComponentType.COMPONENT_TYPE_CURRENCY:
            return 'Currency'
          case ComponentType.COMPONENT_TYPE_BOOL:
            return 'Boolean'
          case ComponentType.COMPONENT_TYPE_FLOAT:
            return 'Decimal'
          default:
            return 'None'
        }
      },
    },
    ...(showActionsColumn
      ? [
          {
            id: 'id',
            label: 'Actions',
            minWidth: 100,
            format: (value: any, row: Component) => (
              <Box display="flex">
                <Tooltip title="Edit">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      React.startTransition(() => {
                        navigate(`/admin/components/${row.id}/edit`)
                      })
                    }
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              </Box>
            ),
          },
        ]
      : []),
  ]

  return (
    <Paper sx={{ mb: 3 }}>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 400px)', minHeight: '300px' }}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead
            sx={{
              '& th': {
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `2px solid ${theme.palette.divider} !important`,
              },
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  id={'cell-col-' + (column.id || '')}
                  sx={{
                    minWidth: column.minWidth,
                    position: 'sticky !important',
                  }}
                  key={column.id}
                  align={column.align}
                >
                  {column.id === 'id' ? (
                    column.label
                  ) : (
                    <TableSortLabel
                      active={sort === column.id}
                      direction={sort === column.id ? (order as 'asc' | 'desc') : 'asc'}
                      onClick={() => handleSort(column.id as string)}
                    >
                      {column.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key="table-body">
            {components.map((row: KeyedObject) => (
              <TableRow id={'table-row-' + row.id} sx={{ py: 3 }} role="checkbox" tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = column.id ? row[column.id] : row
                  return (
                    <TableCell id={'cell-' + column.id} key={column.id} align={column.align}>
                      {column.format ? column.format(value, row as Component) : value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" sx={{ px: '20px' }}>
        <AnimateButton>
          <Tooltip title="Create a new component">
            <Button
              variant="contained"
              startIcon={<PlusOutlined />}
              color="primary"
              onClick={() =>
                React.startTransition(() => {
                  navigate('/admin/components/create')
                })
              }
            >
              Component
            </Button>
          </Tooltip>
        </AnimateButton>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Paper>
  )
}

export default ComponentsTable
