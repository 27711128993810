import { Card, CardContent, Grid2 as Grid, Theme, Typography } from '@mui/material'
import { isBefore } from 'date-fns'
import { useApprovedAccruals } from 'hooks/useAccruals'
import useAuth from 'hooks/useAuth'
import React, { useState } from 'react'
import { getLatestAccrualsByRowId } from 'types/accruals'
import { VariableAllocationRow } from 'types/comp-allocations'
import { ExpandedCompensationPlan } from 'types/comp-plan'
import { Component, Metric, Target } from 'types/components'
import { Period, createIdToPeriod, createLabelToIdMap, parsePeriodDate } from 'types/periods'
import { User } from 'types/user'
import PeriodDetails from './PeriodDetails'
import PeriodNavigation from './PeriodNavigation'
import ProgressOverview from './ProgressOverview'

interface EmployeeDashboardProps {
  user: User
  plan?: ExpandedCompensationPlan
  theme: Theme
  status: number
}

const EmployeeDashboard: React.FC<EmployeeDashboardProps> = ({ user, plan, theme, status }) => {
  const { token } = useAuth()
  const [selectedPeriod, setSelectedPeriod] = useState<string>('FY2024')
  const {
    approvedAccruals,
    isLoading: isLoadingAccruals,
    isError: isErrorAccruals,
  } = useApprovedAccruals(token!, user.id)

  if (isLoadingAccruals) {
    return <Typography>Loading accruals...</Typography>
  }

  if (isErrorAccruals) {
    return <Typography>Error loading accruals. Please try again later.</Typography>
  }

  console.log('Approved Accruals:', approvedAccruals)

  if (!plan || !plan.period || status === 400) {
    return (
      <Grid size={{ xs: 12 }}>
        <Card>
          <CardContent>
            <Typography variant="h5">No valid compensation plan found</Typography>
            <Typography variant="body1">Please contact your manager or HR department for assistance.</Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const periodIdMap = createIdToPeriod(plan.period)

  const today = new Date()

  const periodsToInclude = (periodIdMap: Record<string, Period>): string[] => {
    const periods = Object.values(periodIdMap)
    return periods
      .filter((period) => isBefore(parsePeriodDate(period.startDate), today))
      .map((period) => period.id as string)
  }

  const includedPeriods = periodsToInclude(periodIdMap)

  const filteredTargets = plan.targets.filter((target) => {
    if (!target.periodId) return false

    const period = periodIdMap[target.periodId]
    if (!period || !period.startDate) {
      return false
    }

    return includedPeriods.includes(target.periodId)
  })

  const filteredAndSortedTargets = filteredTargets.sort((a, b) => {
    const periodLabelA = a.periodId && periodIdMap[a.periodId] ? periodIdMap[a.periodId].label : ''
    const periodLabelB = b.periodId && periodIdMap[b.periodId] ? periodIdMap[b.periodId].label : ''

    const cleanLabelA = a.label.replace(periodLabelA, '')
    const cleanLabelB = b.label.replace(periodLabelB, '')

    const labelComparison = cleanLabelA.localeCompare(cleanLabelB)
    if (labelComparison !== 0) return labelComparison

    return periodLabelA.localeCompare(periodLabelB, undefined, { numeric: true, sensitivity: 'base' })
  })

  const findMetricValue = (targetComponentId: string, targetPeriodId: string): number => {
    if (!plan || !plan.metrics) {
      return 0
    }
    const metric = plan.metrics.find((m) => m.componentId === targetComponentId && m.periodId === targetPeriodId)
    return metric && metric.value ? parseFloat(metric.value) : 0
  }

  const calculateProgress = (targetComponentId: string, targetValue: string, targetPeriodId: string): number => {
    const metricValue = findMetricValue(targetComponentId, targetPeriodId)
    const safeTargetValue = parseFloat(targetValue) || 1
    const progress = (metricValue / safeTargetValue) * 100
    return Math.min(progress, 100)
  }

  const activeRows = plan.plan.variableAllocations.reduce<VariableAllocationRow[]>((acc, table) => {
    table.rows
      .filter((row) => includedPeriods.includes(row.periodId as string))
      .forEach((row) => {
        acc.push(row)
      })
    return acc
  }, [])

  const slugDictionary = (
    components: Component[] | null,
    targets: Target[] | null,
    metrics: Metric[] | null
  ): Record<string, string> => {
    const result: Record<string, string> = {}

    // Create a map for quick lookup of component slugs by componentId
    const componentMap: Record<string, string> = {}
    components?.forEach((component) => {
      if (component.id) {
        componentMap[component.id] = component.slug
      }
    })

    // Populate result with component slugs and corresponding metric values
    metrics?.forEach((metric) => {
      if (metric.componentId && componentMap[metric.componentId]) {
        result[componentMap[metric.componentId]] = metric.value
      }
    })

    // Populate result with target slugs and corresponding target values
    targets?.forEach((target) => {
      result[target.slug] = target.value
    })

    return result
  }

  const evaluateExpression = (expression: string, slugDict: Record<string, string>): boolean => {
    const replacedExpression = expression.replace(/\b\w+\b/g, (match) => slugDict[match] || match)

    try {
      return eval(replacedExpression) // Be cautious with eval, ensure expressions are safe
    } catch (error) {
      // console.error('Error evaluating expression:', replacedExpression, error)
      return false
    }
  }

  const calculateResult = (
    variableCompensation: number,
    row: VariableAllocationRow,
    slugDict: Record<string, string>
  ): number => {
    for (let i = 0; i < row.formulaRequirements.length; i++) {
      if (evaluateExpression(row.formulaRequirements[i], slugDict)) {
        let formulaOption = row.formulaOptions[i].replace(/\b\w+\b/g, (match) => slugDict[match] || match)
        const ratio = eval(formulaOption)

        const variableComp = variableCompensation * row.allocationPercentage
        const maxVariableComp = variableComp * row.defaultPayout

        let result = variableComp * ratio

        if (ratio > 1) {
          const acceleratorExpression = row.achievementMultiplier.replace('a', result.toString())
          result = eval(acceleratorExpression)
        }

        if (result > maxVariableComp) {
          result = maxVariableComp
        }

        return result
      }
    }
    return 0
  }

  const periodLabels = ['FY2024', 'H1', 'H2', 'Q1', 'Q2', 'Q3', 'Q4']

  const periodLabelMap = createLabelToIdMap(plan.period)

  const slugDict = slugDictionary(plan.components || [], plan.targets || [], plan.metrics || [])

  const filteredAccruals = getLatestAccrualsByRowId(approvedAccruals || [])

  // Add this logging statement just before the return statement
  console.log('PeriodNavigation props:', {
    periods: periodLabels,
    selectedPeriod,
    onSelectPeriod: setSelectedPeriod,
    theme,
    periodLabelMap,
    periodIdMap,
  })

  return (
    <Grid size={{ xs: 12 }}>
      <ProgressOverview
        key="progress-overview"
        plan={plan}
        slugDictionary={slugDict}
        calculateResult={calculateResult}
        activeRows={activeRows}
        approvedAccruals={filteredAccruals}
      />
      <PeriodNavigation
        key="period-navigation"
        periods={periodLabels}
        selectedPeriod={selectedPeriod}
        onSelectPeriod={setSelectedPeriod}
        theme={theme}
        periodLabelMap={periodLabelMap}
        periodIdMap={periodIdMap}
      />
      {periodLabels.map((period) =>
        period === selectedPeriod ? (
          <PeriodDetails
            key={`period-details-${period}`}
            periodLabel={period}
            periodLabelMap={periodLabelMap}
            targets={filteredAndSortedTargets}
            periodIdMap={periodIdMap}
            findMetricValue={findMetricValue}
            calculateProgress={calculateProgress}
            plan={plan}
            slugDictionary={slugDict}
            calculateResult={calculateResult}
            activeRows={activeRows}
            accruals={filteredAccruals}
            theme={theme}
          />
        ) : null
      )}
    </Grid>
  )
}

export default EmployeeDashboard
