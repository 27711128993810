import { SearchOutlined } from '@ant-design/icons'
import {
  Button,
  CircularProgress,
  Grid2 as Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { useSearchOrganizationTemplates } from 'hooks/useTemplates'
import React, { FC, useCallback, useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { KeyedObject } from 'types/root'
import { getOrganizationLabelMap } from 'types/user-profile'
import { debounce } from 'utils/debounce'
import { wholePercentFormatter } from 'utils/formatting'
import useLocalStorage from 'hooks/useLocalStorage'
import { TemplateQueryParams } from 'types/comp-template'
import type { JSX } from 'react'

interface ColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: KeyedObject) => string | boolean | JSX.Element
}

const SelectTemplate: FC = () => {
  const { userId } = useParams<{ userId: string }>()
  const navigate = useNavigate()
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [sort, setSort] = useLocalStorage<string>('template-select-sort', 'lastName')
  const [order, setOrder] = useLocalStorage<string>('template-select-order', 'asc')
  const tableContainerRef = useRef(null)

  const { token } = useAuth()
  const { profile } = useProfile(token!)

  const queryParams: TemplateQueryParams = {
    organizationIds: selectedOrganizations,
    searchTerm: search,
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    sort: [{ field: sort, order: order }],
  }

  const { templates, isLoading } = useSearchOrganizationTemplates(token!, queryParams)

  useEffect(() => {
    if (tableContainerRef.current) {
      ;(tableContainerRef.current as HTMLElement).scrollTop = 0
    }
  }, [sort, order, page, rowsPerPage])

  // Handle sorting
  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setSearch(value)
    }, 300),
    []
  )

  const organizationMap = profile != null ? getOrganizationLabelMap(profile) : {}

  const columns: ColumnProps[] = [
    { id: 'label', label: 'Name', minWidth: 170 },
    {
      id: 'organizationName',
      label: 'Team',
      minWidth: 170,
      format: (value: any, row: any) => {
        if (Array.isArray(row.organizationIds) && row.organizationIds.length >= 4) {
          return `(${row.organizationIds.length}) Teams`
        }

        return Array.isArray(row.organizationIds)
          ? row.organizationIds.map((id: string) => organizationMap[id]).join(', ')
          : organizationMap[row.organizationIds]
      },
    },
    { id: 'position', label: 'Position', minWidth: 170 },
    {
      id: 'variable_percent',
      label: 'Variable Compensation',
      minWidth: 140,
      format: (value: any, row: any) => wholePercentFormatter.format(row.variablePercent),
    },
    {
      id: 'variable_cap',
      label: 'Max Payout',
      minWidth: 170,
      format: (value: any, row: any) => wholePercentFormatter.format(row.variableCap),
    },
    {
      id: 'id',
      label: 'Action',
      minWidth: 120,
      format: (templateId: string) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/create-comp-plan?withUserId=${userId}&templateId=${templateId}`)}
        >
          Select
        </Button>
      ),
    },
  ]

  return (
    <div style={{ position: 'relative' }}>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
        <Grid size={{ xs: 4 }}>
          <Typography variant="h2">Select Template</Typography>
        </Grid>
        <Grid size={{ xs: 4 }} container justifyContent="center">
          <TextField
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            sx={{
              userSelect: 'none',
              width: '100%',
              '& .MuiInputLabel-root': {
                backgroundColor: 'transparent',
              },
            }}
            variant="standard"
            onChange={(e) => debouncedSetSearch(e.target.value)}
          />
        </Grid>
        <Grid size={{ xs: 4 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <MultiOrganizationSelector
            userProfile={profile}
            selectedOrganizationIds={selectedOrganizations}
            handleChange={setSelectedOrganizations}
            width="80%"
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={12}>
          <Grid
            size={{ xs: 12 }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress color="primary" />
            Loading
          </Grid>
        </Grid>
      ) : (
        <Paper>
          <TableContainer ref={tableContainerRef} sx={{ maxHeight: 860, minHeight: 430 }}>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.id !== 'label' && column.id !== 'position' ? (
                        column.label
                      ) : (
                        <TableSortLabel
                          active={sort === column.id}
                          direction={sort === column.id ? (order as 'asc' | 'desc') : 'asc'}
                          onClick={() => handleSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.length > 0 ? (
                  templates.map((row: KeyedObject) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value, row) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={templates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  )
}

export default SelectTemplate
