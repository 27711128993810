import useSWR, { mutate } from 'swr'
import {
  Component,
  ComponentDetails,
  ComponentQueryParams,
  ComponentSearchParams,
  ComponentSearchResponse,
  ExpandedTarget,
  ExpandedTargetSearchResponse,
  LabelResponse,
  MetricDetailsParams,
  MetricDetailsResponse,
  MetricQueryParams,
  MetricSearchResponse,
  NeededTargetMetric,
  SearchNeededTargetMetricsParams,
  TargetDetailsParams,
  TargetDetailsResponse,
  TargetQueryParams,
  TargetSearchResponse,
} from 'types/components'
import { fetchWithToken, fetchWithTokenWithStatus, postWithToken } from './http'
import { generateDefaultQueryParams } from 'types/default-params'

export function useFetchOrganizationComponents(token: string, orgId: string, params: [[string, string]] | null) {
  const { data, error, isLoading } = useSWR(
    [`components/organization/${orgId}`, token, params],
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    components: data ? (data as Component[]) : [],
    isLoading,
    isError: error,
  }
}

interface UseGetComponentByIdReturn {
  details: ComponentDetails | null
  isLoading: boolean
  isError: any
  refetch: () => Promise<ComponentDetails | null>
}

export function useGetComponentById(
  token: string,
  componentID: string,
  shouldFetch: boolean
): UseGetComponentByIdReturn {
  const { data, error, isValidating, mutate } = useSWR(
    shouldFetch ? [`components/${componentID}`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: true,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      dedupingInterval: 0,
    }
  )

  const refetch = async (): Promise<ComponentDetails | null> => {
    const refetchedData = await mutate()
    return refetchedData as ComponentDetails | null
  }

  return {
    details: data as ComponentDetails | null,
    isLoading: !data && isValidating,
    isError: error,
    refetch,
  }
}

export function useFetchExpandedTargets(token: string, rootOrganizationId: string, rootPeriodId: string) {
  const shouldFetch = token != null && token.trim() !== ''

  const { data, error, isValidating } = useSWR(
    shouldFetch && rootOrganizationId && rootPeriodId
      ? [`components/period/target/expanded?organizationId=${rootOrganizationId}&rootPeriodId=${rootPeriodId}`, token]
      : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: false,
      revalidateIfStale: true,
      revalidateOnFocus: true,
    }
  )

  return {
    targets: data as ExpandedTarget[],
    isLoadingExpandedTargets: !data && isValidating,
    isError: error,
  }
}

export function useSearchTargets(token: string, queryParams: TargetQueryParams, shouldFetch: boolean = true) {
  let params = generateDefaultQueryParams(queryParams)
  params.push(['organizationIds', queryParams.organizationIds.join(',')])
  params.push(['periodIds', queryParams.periodIds.join(',')])
  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`components/targets/search`, token, params] : null, // Conditional key based on shouldFetch
    ([url, token, queryParams]) => fetchWithToken(url, token, queryParams)
  )

  const handleRefetch = () => {
    mutate(['components/targets/search', token, params], fetchWithToken('components/targets/search', token!, params))
  }

  return {
    targets: data as TargetSearchResponse,
    isLoading,
    isError: error,
    refetch: handleRefetch,
  }
}

export function useSearchExpandedTargets(token: string, params: ComponentSearchParams, shouldFetch: boolean) {
  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`components/targets/expanded/search`, token, params] : null,
    ([url, token, params]) => postWithToken(url, token, params)
  )

  return {
    targets: data as ExpandedTargetSearchResponse,
    isLoading,
    isError: error,
  }
}

export function useSearchMetrics(token: string, queryParams: MetricQueryParams, shouldFetch: boolean = true) {
  let params = generateDefaultQueryParams(queryParams)
  params.push(['periodIds', queryParams.periodIds.join(',')])
  params.push(['organizationIds', queryParams.organizationIds.join(',')])
  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`components/period/search`, token, params] : null, // Conditional key based on shouldFetch
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    metrics: data as MetricSearchResponse,
    isLoading,
    isError: error,
  }
}

export function useSearchNeededTargetMetrics(
  token: string,
  params: SearchNeededTargetMetricsParams,
  shouldFetch: boolean
) {
  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`components/targets/metrics/search`, token, params] : null,
    ([url, token, params]) => postWithToken(url, token, params)
  )

  return {
    targets: data as NeededTargetMetric[],
    isLoading,
    isError: error,
  }
}

export function useFetchTargetDetails(token: string, params: TargetDetailsParams, shouldFetch: boolean) {
  const key = shouldFetch ? [`components/targets/details`, token, JSON.stringify(params)] : null
  const { data, error, isLoading } = useSWR(
    key,
    async ([url, token, paramsString]) => {
      const parsedParams = JSON.parse(paramsString)
      return postWithToken(url, token, parsedParams)
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
    }
  )

  const revalidate = () => {
    if (key) {
      mutate(key)
    }
  }

  return {
    targetDetails: data as TargetDetailsResponse,
    isLoading,
    isError: error,
    revalidate,
  }
}

export function useFetchMetricDetails(token: string, params: MetricDetailsParams, shouldFetch: boolean) {
  const key = shouldFetch ? [`components/metrics/details`, token, JSON.stringify(params)] : null
  const { data, error, isLoading } = useSWR(
    key,
    async ([url, token, paramsString]) => {
      const parsedParams = JSON.parse(paramsString)
      return postWithToken(url, token, parsedParams)
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
    }
  )

  const revalidate = () => {
    if (key) {
      mutate(key)
    }
  }

  return {
    metricDetails: data as MetricDetailsResponse,
    isLoading,
    isError: error,
    revalidate,
  }
}

export function useSearchComponents(token: string, queryParams: ComponentQueryParams, shouldFetch: boolean = true) {
  let params = generateDefaultQueryParams(queryParams)
  params.push(['periodIds', queryParams.periodIds.join(',')])
  params.push(['organizationIds', queryParams.organizationIds.join(',')])
  params.push(['userIds', queryParams.userIds.join(',')])
  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`components/search`, token, params] : null,
    async ([url, token, params]) => {
      return fetchWithToken(url, token, params)
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
    }
  )

  const revalidate = () => {
    if (token && params) {
      mutate([`components/search`, token, params])
    }
  }

  return {
    componentSearchResult: data as ComponentSearchResponse,
    isLoading,
    isError: error,
    revalidate,
  }
}

export function useGetComponentLabels(token: string, organizationId: string) {
  const { data, error, isLoading } = useSWR(
    token && organizationId ? [`components/${organizationId}/labels`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null)
  )

  return {
    labels: data ? (data as LabelResponse).labels : [],
    isLoading,
    isError: error,
  }
}

export function useGetRootOrganizationComponentLabelsWithStatus(
  token: string,
  organizationId: string,
  shouldFetch: boolean
) {
  const { data, error, isLoading } = useSWR(
    shouldFetch && token && organizationId ? [`components/organization/${organizationId}/labels`, token] : null,
    ([url, token]) => fetchWithTokenWithStatus(url, token, null)
  )

  return {
    labels: data?.data ? (data.data as LabelResponse).labels : [],
    status: data?.status,
    isLoading,
    isError: error,
  }
}
