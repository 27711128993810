export interface SortParams {
  field: string
  order: string
}

export interface PaginationParams {
  page: number
  perPage: number
}

export interface DefaultQueryParams {
  searchTerm: string
  pagination: PaginationParams
  sort: SortParams[]
}

export const generateDefaultQueryParams = (params: DefaultQueryParams): [string, string][] => {
  return [
    ['searchTerm', params.searchTerm],
    ['page', `${params.pagination.page}`],
    ['perPage', `${params.pagination.perPage}`],
    ['sort', params.sort.map((sort) => `${sort.field}`).join(',')],
    ['order', params.sort.map((sort) => `${sort.order}`).join(',')],
  ]
}
