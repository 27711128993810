import { Card, CardContent, Grid2 as Grid, MenuItem, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { ApprovalChain } from 'types/approvals'
import { OrganizationSettingDTO } from 'types/organization'

interface ApprovalSettingsProps {
  settings: OrganizationSettingDTO[]
  handleChange: (index: number, value: string) => void
  approvalChains: ApprovalChain[] | undefined
}

const ApprovalSettings: FC<ApprovalSettingsProps> = ({ settings, handleChange, approvalChains }) => {
  return (
    <Card elevation={3}>
      <CardContent>
        <Typography variant="h5" gutterBottom mb={2}>
          Approval Chains
        </Typography>
        <Grid container spacing={3} p={2}>
          {['Metric', 'Accrual', 'Target', 'Plan'].map((type) => (
            <Grid size={{ xs: 12, sm: 6 }} key={type}>
              <TextField
                fullWidth
                label={`${type} Approval Chain`}
                select
                variant="standard"
                value={settings.find((s) => s.settingName === `${type} Approval Chain`)?.settingValue || ''}
                onChange={(e) =>
                  handleChange(
                    settings.findIndex((s) => s.settingName === `${type} Approval Chain`),
                    e.target.value
                  )
                }
              >
                {approvalChains && approvalChains.length > 0 ? (
                  approvalChains.map((chain) => (
                    <MenuItem key={chain.id} value={chain.id?.toString() ?? ''}>
                      {chain.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No approval chains available</MenuItem>
                )}
              </TextField>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ApprovalSettings
