import { FormControl, InputLabel, Select, useTheme } from '@mui/material'
import { RichTreeView } from '@mui/x-tree-view'
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks'
import { TreeViewBaseItem } from '@mui/x-tree-view/models'
import React, { FC, useEffect, useRef } from 'react'
import { ThemeMode } from 'types/config'
import { Organization } from 'types/organization'
import { getUniqueOrganizationRoles, UserProfile } from 'types/user-profile'
import './MultiOrganizationSelector.css'

interface MultiOrganizationSelectorProps {
  userProfile: UserProfile
  selectedOrganizationIds: string[]
  handleChange: (selected: string[]) => void
  className?: string
  style?: React.CSSProperties
  width?: string | number
  label?: string
  placeholder?: string
  disabled?: boolean
  selectAll?: boolean
}

const MultiOrganizationSelector: FC<MultiOrganizationSelectorProps> = ({
  userProfile,
  selectedOrganizationIds,
  handleChange,
  className,
  style,
  width = '100%',
  label = 'Filtered Teams',
  placeholder = 'Selected Teams',
  disabled = false,
  selectAll = false,
}) => {
  const theme = useTheme()
  const apiRef = useTreeViewApiRef()
  const toggledItemRef = useRef<{ [itemId: string]: boolean }>({})

  const uniqueUserRoles = getUniqueOrganizationRoles(userProfile)
  useEffect(() => {
    if (selectAll) {
      handleChange(uniqueUserRoles.map((role) => role.organization.id))
    }
  }, [selectAll])

  const handleItemSelectionToggle = (event: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
    toggledItemRef.current[itemId] = isSelected
  }

  const handleSelectedItemsChange = (event: React.SyntheticEvent, newSelectedItems: string[]) => {
    const itemsToSelect: string[] = []
    const itemsToUnSelect: { [itemId: string]: boolean } = {}
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      console.log(apiRef.current)
      const item = apiRef.current!.getItem(itemId)
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item))
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true
        })
      }
    })

    const newSelectedItemsWithChildren = Array.from(
      new Set([...newSelectedItems, ...itemsToSelect].filter((itemId) => !itemsToUnSelect[itemId]))
    )

    handleChange(newSelectedItemsWithChildren)
    toggledItemRef.current = {}
  }

  const getItemDescendantsIds = (item: TreeViewBaseItem) => {
    const ids: string[] = []
    item.children?.forEach((child) => {
      ids.push(child.id)
      ids.push(...getItemDescendantsIds(child))
    })
    return ids
  }

  const renderTree = (nodes: Organization[]): TreeViewBaseItem[] => {
    const userRoles = getUniqueOrganizationRoles(userProfile)
    const organizationMap = new Map(userRoles.map((role) => [role.organization.id, role.organization]))

    const isTopLevelOrg = (org: Organization) => !org.parentId || !organizationMap.has(org.parentId)

    const buildTree = (org: Organization): TreeViewBaseItem => ({
      id: org.id,
      label: org.name,
      children: uniqueUserRoles
        .filter((role) => role.organization.parentId === org.id)
        .map((role) => buildTree(role.organization))
        .sort((a, b) => a.label.localeCompare(b.label)),
    })

    return nodes
      .filter(isTopLevelOrg)
      .map(buildTree)
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  const rootOrganizations = uniqueUserRoles
    .map((role) => role.organization)
    .filter((org) => !org.parentId || !uniqueUserRoles.some((role) => role.organization.id === org.parentId))
  const firstRootItemId = rootOrganizations[0]?.id

  const hoverColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  return (
    <div className={className} style={{ ...style, marginBottom: 0, paddingBottom: 0, width: width }}>
      <FormControl variant="standard" sx={{ width: '100%' }}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={selectedOrganizationIds}
          displayEmpty
          multiple={true}
          renderValue={() =>
            selectedOrganizationIds.length > 0 ? `${placeholder} (${selectedOrganizationIds.length})` : ''
          }
          variant="standard"
          style={{ width: '100%' }}
          disabled={disabled}
        >
          <RichTreeView
            multiSelect
            checkboxSelection
            style={{ width: '100%' }}
            apiRef={apiRef}
            items={renderTree(rootOrganizations)}
            selectedItems={selectedOrganizationIds}
            onSelectedItemsChange={handleSelectedItemsChange}
            onItemSelectionToggle={handleItemSelectionToggle}
            defaultExpandedItems={firstRootItemId ? [firstRootItemId] : []}
            sx={{
              '& .MuiTreeItem-content:hover': {
                backgroundColor: hoverColor,
              },
              '& .MuiTreeItem-content.Mui-selected:hover': {
                backgroundColor: theme.palette.action.selected,
              },
            }}
          />
        </Select>
      </FormControl>
    </div>
  )
}

export default MultiOrganizationSelector
