import { Link } from 'react-router-dom'

// material-ui
import { Grid2 as Grid, Stack, Typography } from '@mui/material'

// project import
import useAuth from 'hooks/useAuth'
import AuthWrapper from 'sections/auth/AuthWrapper'
import AuthForgotPassword from 'sections/auth/auth-forms/AuthForgotPassword'

// ================================|| FORGOT PASSWORD ||================================ //

const ForgotPassword = () => {
  const { isLoggedIn } = useAuth()

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            <Typography variant="h3">Forgot Password</Typography>
            <Typography
              component={Link}
              to={isLoggedIn ? '/auth/login' : '/login'}
              variant="body1"
              sx={{ textDecoration: 'none' }}
              color="primary"
            >
              Back to Login
            </Typography>
          </Stack>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <AuthForgotPassword />
        </Grid>
      </Grid>
    </AuthWrapper>
  )
}

export default ForgotPassword
