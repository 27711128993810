import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AnimateButton from 'components/@extended/AnimateButton'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { useCreateLedgerAccount, useFetchLedgerAccounts_V2, useUpdateLedgerAccount } from 'hooks/useLedgerAccounts'
import LedgerAccountDialog from 'pages/Admin/LedgerAccounts/ManageLedgerAccounts/LedgerAccountDialog'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import {
  LedgerAccount,
  LedgerAccountDTO,
  LedgerAccountsQueryParams,
  FetchLedgerAccountsResponse,
} from 'types/ledger-account'
import { getRootOrganizationId } from 'types/user-profile'
import { KeyedObject } from 'types/root'
import type { JSX } from 'react'

interface ColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: KeyedObject) => string | boolean | JSX.Element
}

const ManageLedgerAccountsSubComponent: React.FC = () => {
  const theme = useTheme()
  const { token } = useAuth()
  const { profile } = useProfile(token!)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [sort, setSort] = useState<string>('label')
  const [order, setOrder] = useState<string>('asc')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<LedgerAccount | null>(null)
  const [rootOrganizationId, setRootOrganizationId] = useState<string | null>(null)
  const [ledgerAccounts, setLedgerAccounts] = useState<FetchLedgerAccountsResponse>({
    ledgerAccounts: [],
    paginationInfo: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      perPage: 0,
    },
  })

  const queryParams: LedgerAccountsQueryParams = {
    organizationIds: [rootOrganizationId!],
    searchTerm: '',
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    sort: [{ field: sort, order: order }],
  }

  const { ledgerAccountsFetchResults, revalidate } = useFetchLedgerAccounts_V2(token!, queryParams)

  const { createLedgerAccount, isLoading: isCreating } = useCreateLedgerAccount(token!)
  const { updateLedgerAccount } = useUpdateLedgerAccount(token!)

  useEffect(() => {
    if (ledgerAccountsFetchResults) {
      console.log('ledgerAccountsFetchResults', ledgerAccountsFetchResults)
      setLedgerAccounts(ledgerAccountsFetchResults)
    }
  }, [ledgerAccountsFetchResults])

  useEffect(() => {
    if (profile) {
      setRootOrganizationId(getRootOrganizationId(profile))
    }
  }, [profile])

  useEffect(() => {
    console.log('selectedAccount', selectedAccount)
  }, [selectedAccount])

  // Handle sorting
  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenDialog = useCallback((account: LedgerAccount | null = null) => {
    setSelectedAccount(account ? { ...account } : null)
    setDialogOpen(true)
  }, [])

  const handleCloseDialog = useCallback(() => {
    setSelectedAccount(null)
    setDialogOpen(false)
  }, [])

  const handleDialogSubmit = useCallback(
    (newAccount: LedgerAccountDTO) => {
      if (selectedAccount) {
        updateLedgerAccount(selectedAccount.id, newAccount)
          .then((response) => {
            setDialogOpen(false)
            revalidate()
          })
          .catch((error) => {
            console.error('Failed to update ledger account:', error)
          })
      } else {
        createLedgerAccount(newAccount)
          .then((createdAccount) => {
            setDialogOpen(false)
            revalidate() // Optional: refetch to ensure server-side consistency
          })
          .catch((error) => {
            // Handle error (e.g., show an error message)
            console.error('Failed to create ledger account:', error)
          })
      }
      setSelectedAccount(null)
    },
    [selectedAccount, createLedgerAccount, revalidate, updateLedgerAccount]
  )

  const columns: ColumnProps[] = [
    { id: 'externalId', label: 'External ID', minWidth: 100 },
    { id: 'label', label: 'Label', minWidth: 170 },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 70,
      align: 'center',
      format: (value: any, row: any) => (
        <IconButton color="primary" onClick={() => handleOpenDialog(row)}>
          <EditOutlined />
        </IconButton>
      ),
    },
  ]

  return (
    <div>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h2">Ledger Accounts</Typography>
      </Box>

      <Paper>
        <TableContainer sx={{ maxHeight: 'calc(100vh - 400px)', minHeight: '300px' }}>
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead
              sx={{
                '& th': {
                  borderTop: `1px solid ${theme.palette.divider}`,
                  borderBottom: `2px solid ${theme.palette.divider} !important`,
                },
              }}
            >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      minWidth: column.minWidth,
                      position: 'sticky !important',
                      textAlign: column.align || 'left',
                    }}
                  >
                    {column.id === 'actions' ? (
                      column.label
                    ) : (
                      <TableSortLabel
                        active={sort === column.id}
                        direction={sort === column.id ? (order as 'asc' | 'desc') : 'asc'}
                        onClick={() => {
                          handleSort(column.id)
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ledgerAccounts.ledgerAccounts.length > 0 ? (
                ledgerAccounts.ledgerAccounts.map((row) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.id} sx={{ py: 3 }}>
                      {columns.map((column) => {
                        const value = row[column.id as keyof LedgerAccount]
                        return (
                          <TableCell key={column.id} align={column.align as 'left' | 'center' | 'right' | undefined}>
                            {column.format ? column.format(value, row) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" sx={{ px: '20px' }}>
          <AnimateButton>
            <Tooltip title="Add a ledger account">
              <Button
                variant="contained"
                startIcon={<PlusOutlined />}
                color="primary"
                onClick={() => {
                  setSelectedAccount(null)
                  handleOpenDialog()
                }}
              >
                Ledger Account
              </Button>
            </Tooltip>
          </AnimateButton>

          {/* table pagination */}
          <TablePagination
            rowsPerPageOptions={[100, 250, 500]}
            component="div"
            count={ledgerAccounts.ledgerAccounts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>

      <LedgerAccountDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleDialogSubmit}
        account={selectedAccount}
        organizationId={rootOrganizationId!}
        isLoading={isCreating}
      />
    </div>
  )
}

const ManageLedgerAccounts: React.FC = () => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ManageLedgerAccountsSubComponent />
    </Suspense>
  )
}

export default ManageLedgerAccounts
