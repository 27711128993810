import * as React from 'react'
import { useNavigate } from 'react-router-dom'

// material-ui
import {
  Box,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import type { JSX } from 'react'
// project imports

// types
import { KeyedObject } from 'types/root'

import { useGlobalState } from 'contexts/GlobalStateContext'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'

import { currencyFormatter, twoDigitPercentFormatter } from 'utils/formatting'

// Ant Design icons
import { EditOutlined } from '@ant-design/icons'
import { useFetchOrganizationPlans } from 'hooks/usePlans'

// table columns
interface ColumnProps {
  id: string | null
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any) => string | boolean | JSX.Element
}

export default function PlansTable() {
  const theme = useTheme()
  const navigate = useNavigate()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const { token } = useAuth()
  const { profile } = useProfile(token!)
  const { purcentAppState } = useGlobalState()
  const { plans, isLoading } = useFetchOrganizationPlans(
    token!,
    purcentAppState?.selectedOrganization?.id || profile?.roles[0].organization.id,
    null
  )

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setRowsPerPage(+event?.target?.value!)
    setPage(0)
  }

  const columns: ColumnProps[] = [
    { id: 'label', label: 'Name', minWidth: 170 },
    { id: 'position', label: 'Position', minWidth: 170 },
    {
      id: 'variablePercentage',
      label: 'Variable Compensation',
      minWidth: 140,
      format: (value) => {
        return twoDigitPercentFormatter.format(value)
      },
    },
    {
      id: 'variableCap',
      label: 'Max Payout',
      minWidth: 170,
      format: (value) => {
        return currencyFormatter.format(value)
      },
    },
    {
      id: 'id',
      label: 'Actions',
      minWidth: 100,
      format: (planId: string) => (
        <Box display="flex">
          <Box mx={0}>
            <Tooltip title="Edit">
              <IconButton color="primary" onClick={() => navigate(`/plan/${planId}/edit`)}>
                <EditOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ),
    },
  ]

  return isLoading ? (
    <Grid container spacing={12}>
      <Grid
        size={{ xs: 12 }}
        style={{
          display: 'flex', // Enables flexbox
          justifyContent: 'center', // Centers horizontally
          alignItems: 'center', // Centers vertically
          height: '100%', // Takes full height of the container
        }}
      >
        <CircularProgress color="primary" />
        Loading
      </Grid>
    </Grid>
  ) : (
    <Paper>
      {/* table */}
      <TableContainer sx={{ maxHeight: 860, minHeight: 430 }}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead
            sx={{
              '& th': {
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `2px solid ${theme.palette.divider} !important`,
              },
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  id={'cell-col-' + (column.id || '')}
                  sx={{
                    minWidth: column.minWidth,
                    position: 'sticky !important',
                  }}
                  key={column.id}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: KeyedObject) => (
              <TableRow id={'tble-row-' + row.plan.id} sx={{ py: 3 }} role="checkbox" tabIndex={-1} key={row.plan.id}>
                {columns.map((column) => {
                  const value = column.id ? row.plan[column.id] : row.plan
                  return (
                    <TableCell id={'cell-' + column.id} key={column.id} align={column.align}>
                      {column.format ? column.format(value) : value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      {/* table pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={plans?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
