import { Container, Grid2 as Grid } from '@mui/material'
import ContactForm from 'sections/extra-pages/contact/ContactForm'
import ContactHeader from 'sections/extra-pages/contact/ContactHeader'

function contactUS() {
  return (
    <Grid container spacing={12} justifyContent="center" alignItems="center" sx={{ mb: 12 }}>
      <Grid size={{ xs: 12, md: 12 }}>
        <ContactHeader />
      </Grid>
      <Grid size={{ xs: 12, sm: 10, lg: 9 }}>
        <Container maxWidth="lg" sx={{ px: { xs: 0, sm: 2 } }}>
          <ContactForm />
        </Container>
      </Grid>
    </Grid>
  )
}

export default contactUS
