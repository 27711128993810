import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
} from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import SimpleTitle from 'components/SimpleTitle'
import { postWithTokenWithStatus } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import React, { FC, startTransition, useEffect, useState } from 'react'
import { Period } from 'types/periods'

const RunReports: FC = () => {
  const { token } = useAuth()
  const { profile } = useProfile(token!)

  const [reportType, setReportType] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [rootOrganizationId, setRootOrganizationId] = useState<string>('')
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [rootPeriodId, setRootPeriodId] = useState<string>('')
  const [periods, setPeriods] = useState<Period[]>([])
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  })

  const { periods: fetchedPeriods, isLoadingPeriods } = usePeriods(
    token!,
    rootOrganizationId,
    !!token && selectedOrganizations.length > 0 && rootOrganizationId !== ''
  )

  useEffect(() => {
    if (fetchedPeriods) {
      setPeriods(fetchedPeriods.periods || [])
      if (fetchedPeriods.periods.length > 0) {
        setRootPeriodId(fetchedPeriods.periods[0].id!)
      }
    }
  }, [fetchedPeriods])

  useEffect(() => {
    if (profile && profile.roles && profile.roles.length > 0 && rootOrganizationId === '') {
      const orgId = profile.roles[0].organization.rootOrganizationId
      if (orgId) {
        setRootOrganizationId(orgId)
      }
    }
  }, [profile])

  useEffect(() => {
    if (selectedOrganizations.length === 0) {
      setSelectedPeriods([])
    }
  }, [selectedOrganizations])

  const handleReportTypeChange = (event: SelectChangeEvent) => {
    setReportType(event.target.value as string)
  }

  const handleOrganizationChange = (selected: string[]) => {
    startTransition(() => {
      setSelectedPeriods([])
      setSelectedOrganizations(selected)
    })
  }

  const handlePeriodChange = (selected: string[]) => {
    setSelectedPeriods(selected)
  }

  const handleSubmit = async () => {
    try {
      const requestBody = {
        reportType,
        rootOrganizationId,
        organizationIds: selectedOrganizations,
        periodIds: selectedPeriods,
        rootPeriodId,
      }

      const { data, status } = await postWithTokenWithStatus('reports/download', token!, requestBody)

      if (status === 200 && data.downloadUrl) {
        try {
          // Create a temporary anchor element
          const link = document.createElement('a')
          link.href = data.downloadUrl
          link.setAttribute('download', '') // This will use the filename provided by the server

          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          setSnackbar({
            open: true,
            message: 'Download started. Please check your downloads folder.',
            severity: 'success',
          })
        } catch (downloadError) {
          console.error('Error initiating download:', downloadError)
          setSnackbar({ open: true, message: 'Error initiating download', severity: 'error' })
        }
      } else {
        setSnackbar({ open: true, message: data.error || 'Unexpected response from server', severity: 'error' })
      }
    } catch (error) {
      console.error('Error running report:', error)
      setSnackbar({ open: true, message: 'Error running report', severity: 'error' })
    }
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ ...snackbar, open: false })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 150px)' }}>
      <SimpleTitle title="Run Reports" />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Grid container spacing={4} sx={{ width: '50%', mt: 2, mb: 4 }} justifyContent="center">
          <Grid size={{ xs: 12 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel>Report Type</InputLabel>
              <Select
                labelId="report-type-label"
                id="report-type-select"
                value={reportType}
                variant="standard"
                label="Report Type"
                onChange={handleReportTypeChange}
              >
                <MenuItem value="TEAM_ACTUALS">Team Actuals Report</MenuItem>
                <MenuItem value="EMPLOYEE_ACTUALS">Employee Actuals Report</MenuItem>
                <MenuItem value="ACCRUALS">Accruals Report</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <MultiOrganizationSelector
              userProfile={profile}
              selectedOrganizationIds={selectedOrganizations}
              handleChange={handleOrganizationChange}
            />
          </Grid>

          <Grid size={{ xs: 12 }}>
            <MultiPeriodSelector
              periods={periods}
              selectedPeriods={selectedPeriods}
              handleChange={handlePeriodChange}
              disabled={isLoadingPeriods || selectedOrganizations.length === 0 || periods.length === 0}
              label="Selected Periods"
            />
          </Grid>

          <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={
                !reportType ||
                selectedOrganizations.length === 0 ||
                selectedPeriods.length === 0 ||
                rootOrganizationId === ''
              }
            >
              Run Report
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default RunReports
