import { AuditOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogActions,
  Dialog,
  Grid2 as Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import AnimateButton from 'components/@extended/AnimateButton'
import ComponentDisplay from 'components/ComponentDisplay'
import ComponentField from 'components/ComponentField'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useFetchTargetDetails } from 'hooks/useComponents'
import { useSnackbar } from 'notistack'
import { FC, startTransition, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ComponentType } from 'types/components'
import type { JSX } from 'react'

interface ColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: any) => string | JSX.Element
}

const EditTarget: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const componentId = searchParams.get('componentId')
  const organizationId = searchParams.get('organizationId')
  const periodId = searchParams.get('periodId')
  const userId = searchParams.get('userId')
  const [openCancelDialog, setOpenCancelDialog] = useState(false)

  const { token } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)

  const shouldFetch = !!token && !!componentId && !!organizationId && !!periodId
  console.log('Should fetch:', shouldFetch, { token, componentId, organizationId, periodId })

  const { targetDetails, isLoading, isError, revalidate } = useFetchTargetDetails(
    token!,
    {
      componentId: componentId!,
      organizationId: organizationId!,
      periodId: periodId!,
      ...(userId !== 'null' && { userId }),
    },
    shouldFetch
  )

  const [targetValue, setTargetValue] = useState('')
  const [targetMin, setTargetMin] = useState('')
  const [targetMax, setTargetMax] = useState('')

  useEffect(() => {
    if (targetDetails) {
      setTargetValue(targetDetails.revisions[0].value)
      setTargetMin(targetDetails.component.targetMin)
      setTargetMax(targetDetails.component.targetMax)
    }
  }, [targetDetails])

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await postWithToken('components/targets/update', token!, {
        componentId,
        periodId,
        organizationId,
        value: targetValue,
        ...(targetMin && { targetMin }),
        ...(targetMax && { targetMax }),
        ...(userId !== 'null' && { userId }),
      })

      // Handle successful response
      enqueueSnackbar('Target updated successfully', { variant: 'success' })

      // Revalidate the target details
      revalidate()
    } catch (error: any) {
      // Handle different error scenarios
      if (error.response) {
        switch (error.response.status) {
          case 400:
            enqueueSnackbar('Invalid input data. Please check your entries.', { variant: 'error' })
            break
          case 403:
            enqueueSnackbar('You do not have permission to update this target.', { variant: 'error' })
            break
          default:
            enqueueSnackbar('Failed to update target. Please try again.', { variant: 'error' })
        }
      } else {
        enqueueSnackbar('An unexpected error occurred. Please try again.', { variant: 'error' })
      }
    } finally {
      setIsSaving(false)
    }
  }

  const handleCancelConfirmation = () => {
    setOpenCancelDialog(false)
    startTransition(() => {
      navigate('/admin/components/targets')
    })
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (isError || !targetDetails) {
    return <Typography variant="h6">Error loading target details</Typography>
  }

  const orgSettings = targetDetails.coreSettings

  const planColumns: ColumnProps[] = [
    { id: 'name', label: 'Name', minWidth: 170, format: (value, row) => `${row.firstName} ${row.lastName}` },
    {
      id: 'approvalStatus',
      label: 'Status',
      minWidth: 100,
      format: (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 80,
      align: 'center',
      format: (value, row) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="View Plan">
            <IconButton
              color="primary"
              onClick={() => {
                startTransition(() => {
                  navigate(`/employee/${row.userId}/compplan`)
                })
              }}
            >
              <EyeOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Plan">
            <IconButton
              color="primary"
              onClick={() => {
                startTransition(() => {
                  navigate(`/create-comp-plan?userId=${row.userId}`)
                })
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Approval Batch">
            <IconButton
              color="primary"
              onClick={() => {
                startTransition(() => {
                  navigate(`/admin/approvals/${row.approvalBatchId}/edit`)
                })
              }}
            >
              <AuditOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ]

  const revisionColumns: ColumnProps[] = [
    { id: 'revision', label: 'Revision', minWidth: 100 },
    {
      id: 'approvalStatus',
      label: 'Status',
      minWidth: 150,
      format: (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
    },
    {
      id: 'value',
      label: 'Value',
      minWidth: 120,
      format: (value) => (
        <ComponentDisplay
          componentType={targetDetails.component.componentType as ComponentType}
          value={value}
          currency={orgSettings.currency}
          currencyDecimals={orgSettings.currencyDecimals}
        />
      ),
    },
    { id: 'createdAt', label: 'Created At', minWidth: 170, format: (value) => new Date(value).toLocaleString() },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 80,
      align: 'center',
      format: (value, row) => (
        <Box display="flex" justifyContent="center">
          {row.approvalBatchId && (
            <Tooltip title="View Approval Batch">
              <IconButton
                color="primary"
                onClick={() => {
                  startTransition(() => {
                    navigate(`/admin/approvals/${row.approvalBatchId}/edit`)
                  })
                }}
              >
                <AuditOutlined />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ]

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Edit Target
      </Typography>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Paper elevation={3} sx={{ p: 3, mb: 3, height: '100%' }}>
            <Typography variant="h4" gutterBottom>
              Component Information
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2, pl: 2, pr: 2 }}>
              <Grid size={{ xs: 4 }}>
                <Typography>Component:</Typography>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <Typography fontWeight="bold" color="primary" sx={{ textAlign: 'right' }}>
                  {targetDetails.component.label}
                </Typography>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <Typography>Team:</Typography>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <Typography fontWeight="bold" color="primary" sx={{ textAlign: 'right' }}>
                  {targetDetails.organization.name}
                </Typography>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <Typography>Target Period:</Typography>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <Typography fontWeight="bold" color="primary" sx={{ textAlign: 'right' }}>
                  {targetDetails.period.label}
                </Typography>
              </Grid>
              <Grid size={{ xs: 8 }}>
                {!targetDetails.component.isOrganizationLevel && (
                  <>
                    <Grid size={{ xs: 4 }}>
                      <Typography>{targetDetails.user ? 'Employee:' : ''}</Typography>
                    </Grid>
                    <Grid size={{ xs: 8 }}>
                      {userId === '00000000-0000-0000-0000-000000000000' ? (
                        <Typography fontWeight="bold" color="primary" sx={{ textAlign: 'right' }}>
                          Values represent defaults used when creating employee plans
                        </Typography>
                      ) : targetDetails.user ? (
                        <Typography fontWeight="bold" color="primary" sx={{ textAlign: 'right' }}>
                          {`${targetDetails.user.firstName} ${targetDetails.user.lastName}`}
                        </Typography>
                      ) : null}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Paper elevation={3} sx={{ p: 3, mb: 3, height: '100%', position: 'relative' }}>
            <Typography variant="h4" gutterBottom>
              Target Values
            </Typography>
            <Box mb={2}>
              <ComponentField
                componentType={targetDetails.component.componentType as ComponentType}
                label="Value"
                value={targetValue}
                onChange={(value) => setTargetValue(value)}
                name="target-value"
                currency={orgSettings.currency}
                currencyDecimals={orgSettings.currencyDecimals}
                decimalPlaces={2}
              />
            </Box>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid size={{ xs: 6 }}>
                <Tooltip
                  title={
                    targetDetails.component.isOrganizationLevel
                      ? 'This field is only used on user level components'
                      : ''
                  }
                >
                  <div>
                    <ComponentField
                      componentType={targetDetails.component.componentType as ComponentType}
                      label="Target Minimum"
                      value={targetDetails.component.isOrganizationLevel ? '' : targetMin}
                      onChange={(value) => setTargetMin(value)}
                      name="target-min"
                      currency={orgSettings.currency}
                      currencyDecimals={orgSettings.currencyDecimals}
                      decimalPlaces={2}
                      disabled={targetDetails.component.isOrganizationLevel}
                    />
                  </div>
                </Tooltip>
              </Grid>
              <Grid size={{ xs: 6 }}>
                <Tooltip
                  title={
                    targetDetails.component.isOrganizationLevel
                      ? 'This field is only used on user level components'
                      : ''
                  }
                >
                  <div>
                    <ComponentField
                      componentType={targetDetails.component.componentType as ComponentType}
                      label="Target Maximum"
                      value={targetDetails.component.isOrganizationLevel ? '' : targetMax}
                      onChange={(value) => setTargetMax(value)}
                      name="target-max"
                      currency={orgSettings.currency}
                      currencyDecimals={orgSettings.currencyDecimals}
                      decimalPlaces={2}
                      disabled={targetDetails.component.isOrganizationLevel}
                    />
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" alignItems="center" mt={3}>
              <Stack direction="row" spacing={2}>
                <AnimateButton>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      setOpenCancelDialog(true)
                    }}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
                <AnimateButton>
                  <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
                    {isSaving ? <CircularProgress size={24} /> : 'Save Changes'}
                  </Button>
                </AnimateButton>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Target Revisions
      </Typography>
      <Table component={Paper} size="small" sx={{ p: 3, mb: 3 }}>
        <TableHead>
          <TableRow>
            {revisionColumns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {targetDetails.revisions
            .sort((a, b) => b.revision - a.revision)
            .map((revision) => (
              <TableRow key={revision.revision}>
                {revisionColumns.map((column) => {
                  const value = revision[column.id as keyof typeof revision]
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format ? column.format(value, revision) : value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Employee Plans using this Target
      </Typography>
      <Table component={Paper} size="small" sx={{ p: 3, mb: 3 }}>
        <TableHead>
          <TableRow>
            {planColumns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {targetDetails.plans.map((plan) => (
            <TableRow key={plan.id}>
              {planColumns.map((column) => {
                const value = plan[column.id as keyof typeof plan]
                return (
                  <TableCell key={column.id} align={column.align}>
                    {column.format ? column.format(value, plan) : value}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Templates using this Component
      </Typography>
      <Table component={Paper} size="small" sx={{ p: 3, mb: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell>Label</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {targetDetails.templates.map((template) => (
            <TableRow key={template.id}>
              <TableCell>{template.label}</TableCell>
              <TableCell align="center">
                <Tooltip title="Edit Template">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      startTransition(() => {
                        navigate(`/admin/templates/${template.id}/edit`)
                      })
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Cancel Confirmation Dialog */}
      <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)}>
        <DialogTitle>{'Confirm cancellation'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to cancel updating this target?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)}>No</Button>
          <Button onClick={handleCancelConfirmation} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditTarget
