import { CloseCircleOutlined } from '@ant-design/icons'
import { Box, Grid2 as Grid, IconButton, Tooltip, Typography } from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import useAuth from 'hooks/useAuth'
import { usePeriodOverviews } from 'hooks/useDashboard'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import React, { useEffect, useState, useTransition } from 'react'
import { Period } from 'types/periods'
import ApprovalsTile from './ApprovalsTile'
import OnboardOverviews from './OnnboardOverviews'
import TeamEmployeeOverviews from './TeamEmployeeOverviews'
import TeamOverview from './TeamOverview'
import useLocalStorage from 'hooks/useLocalStorage'

const ManagerDashboard: React.FC = () => {
  const { token } = useAuth()
  const { profile } = useProfile(token!)
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>(
    'dashboard-selected-organizations',
    []
  )
  const [selectedPeriods, setSelectedPeriods] = useLocalStorage<string[]>('dashboard-selected-periods', [])
  const [periods, setPeriods] = useState<Period[]>([])
  const [isPending, startTransition] = useTransition()
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (profile.roles.length > 0 && !isInitialized) {
      const allOrganizationIds = profile.roles.map((role) => role.organization.id)
      if (selectedOrganizations.length === 0) {
        setSelectedOrganizations(allOrganizationIds)
      }
      setIsInitialized(true)
    }
  }, [profile, isInitialized])

  const { periods: fetchedPeriods, isLoadingPeriods } = usePeriods(
    token!,
    selectedOrganizations[0] || '',
    !!token && selectedOrganizations.length > 0
  )

  useEffect(() => {
    if (fetchedPeriods) {
      setPeriods(fetchedPeriods.periods || [])

      // Select the first root period if available
      if (fetchedPeriods.periods && fetchedPeriods.periods.length > 0 && selectedPeriods.length === 0) {
        if (selectedPeriods.length === 0) {
          setSelectedPeriods([fetchedPeriods.periods[0].id!])
        }
      }
    }
  }, [fetchedPeriods])

  const { periodOverviews, isLoading: isLoadingOverviews } = usePeriodOverviews(
    token!,
    {
      rootPeriodId: selectedPeriods[0] || '',
      organizationIds: selectedOrganizations,
    },
    !!token && selectedPeriods.length > 0 && selectedOrganizations.length > 0 && isInitialized
  )

  const handleOrganizationChange = (newSelectedOrganizations: string[]) => {
    startTransition(() => {
      setSelectedOrganizations(newSelectedOrganizations)
    })
  }

  const handlePeriodChange = (newSelectedPeriods: string[]) => {
    startTransition(() => {
      setSelectedPeriods(newSelectedPeriods)
    })
  }

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid size={{ xs: 12, md: 8 }}>
        {!isLoadingOverviews && !isLoadingPeriods && !isPending && periodOverviews ? (
          <>
            <TeamOverview userPeriodOverviews={periodOverviews.userPeriodOverviews} />
            <TeamEmployeeOverviews userPeriodOverviews={periodOverviews.userPeriodOverviews} />
          </>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid size={{ xs: 11 }}>
              <MultiOrganizationSelector
                key="organization-selector"
                userProfile={profile}
                selectedOrganizationIds={selectedOrganizations}
                handleChange={handleOrganizationChange}
              />
            </Grid>
            <Grid size={{ xs: 1 }}>
              <Tooltip title="Reset Organization Filter">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setSelectedOrganizations([])
                  }}
                  sx={{ mt: 2 }}
                >
                  <CloseCircleOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid size={{ xs: 11 }}>
              <MultiPeriodSelector
                key="period-selector"
                periods={periods}
                selectedPeriods={selectedPeriods}
                handleChange={handlePeriodChange}
                rootPeriodOnly={true}
                sortOrder="asc"
                timeFilter="pastOnly"
              />
            </Grid>
            <Grid size={{ xs: 1 }}>
              <Tooltip title="Reset Period Filter">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setSelectedPeriods([])
                  }}
                  sx={{ mt: 2 }}
                >
                  <CloseCircleOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        {periodOverviews && (
          <OnboardOverviews
            planApprovalStats={periodOverviews.planApprovalStats}
            targetMetricCounts={periodOverviews.targetMetricCounts}
            approvedComponentCounts={periodOverviews.approvedComponentCounts}
            accrualCounts={periodOverviews.accrualCounts}
          />
        )}
        <ApprovalsTile />
      </Grid>
    </Grid>
  )
}

export default ManagerDashboard
