import React from 'react'
import useSWR from 'swr'
import { Accrual, AccrualsQueryParams, AccrualsResponse } from 'types/accruals'
import { fetchWithToken } from './http'
import { generateDefaultQueryParams } from 'types/default-params'

export function useAccruals(token: string, queryParams: AccrualsQueryParams, shouldFetch: boolean = true) {
  let params = generateDefaultQueryParams(queryParams)
  params.push(['periodIds', queryParams.periodIds.join(',')])
  params.push(['organizationIds', queryParams.organizationIds.join(',')])
  params.push(['status', queryParams.status])
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch ? [`accruals`, token, params] : null,
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  const refetch = React.useCallback(() => {
    mutate()
  }, [mutate])

  return {
    accruals: data ? (data as AccrualsResponse).accruals : [],
    pagination: data ? (data as AccrualsResponse).pagination : null,
    isLoading,
    isError: error,
    refetch,
  }
}

export function useApprovedAccruals(token: string, targetUserId: string | null) {
  const { data, error, isLoading } = useSWR(
    token ? ['accruals/approved', token, targetUserId] : null,
    ([url, token, targetUserId]) =>
      fetchWithToken(targetUserId ? `${url}?targetUserId=${targetUserId}` : url, token, null)
  )

  return {
    approvedAccruals: data as Accrual[] | undefined,
    isLoading,
    isError: error,
  }
}
