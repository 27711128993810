import { Box, Button, Grid2 as Grid, MenuItem, TextField, useTheme } from '@mui/material'
import PlanAllocationsCard from 'components/Plan/PlanAllocationsCards'
import PlanTargetsCard from 'components/Plan/PlanTargetsCard'
import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'
import { useFetchApprovalBatchById, useSubmitStatusChange } from 'hooks/useApprovals'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ApprovalBatchStatus,
  ApprovalBatchStatusChange,
  ApprovalBatchStatusRequest,
  ApprovalNote,
  ApprovalType,
  ExpandedApprovalItem,
  UserMeta,
} from 'types/approvals'
import { CompensationPlan, ExpandedCompensationPlan } from 'types/comp-plan'
import { LedgerAccount } from 'types/ledger-account'
import AccrualTable from './AccrualTable'
import ApprovalNotes from './ApprovalNotes'
import MetricTable from './MetricTable'
import TargetTable from './TargetTable'

const EditApproval: FC = () => {
  const theme = useTheme()
  const { token } = useAuth()
  const submitStatusChange = useSubmitStatusChange(token!)
  const { enqueueSnackbar } = useSnackbar()

  let { approvalBatchId } = useParams()

  const [employeePlan, setEmployeePlan] = useState<CompensationPlan | null>(null)
  const [planUser, setPlanUser] = useState<UserMeta | null>(null)
  const [action, setAction] = useState<string>('')
  const [notes, setNotes] = useState<ApprovalNote[]>([])
  const [userNameMap, setUserNameMap] = useState<Record<string, string>>({})
  const [history, setHistory] = useState<ApprovalBatchStatusChange[]>([])
  const [isApproved, setIsApproved] = useState(false)

  const [isPlanApproval, setIsPlanApproval] = useState(false)
  const [planUserId, setPlanUserId] = useState<string | undefined>(undefined)
  const [planId, setPlanId] = useState<string | undefined>(undefined)
  const [revision, setRevision] = useState<number | undefined>(undefined)

  const { approvalBatch } = useFetchApprovalBatchById(token!, approvalBatchId!)

  const {
    ledgerAccounts,
    isLoading: isLoadingLedgerAccounts,
    isError: isErrorLedgerAccounts,
  } = useFetchLedgerAccounts(employeePlan?.rootOrganizationId || '', token!, employeePlan?.rootOrganizationId !== '')

  useEffect(() => {
    if (!approvalBatch) return

    const { items, notes, approvalChain, history } = approvalBatch

    if (items) {
      const planItem = items.find((item) => item.approvalType === 'plan')
      if (planItem?.plan) {
        setIsPlanApproval(true)
        setPlanUserId(planItem.user?.id)
        setPlanId(planItem.plan.id || undefined)
        setRevision(planItem.plan.revision || undefined)
        setEmployeePlan(planItem.plan)
        setPlanUser(planItem.user || null)
      } else {
        setIsPlanApproval(false)
      }
    }

    if (notes) {
      setNotes(notes)
    }
    if (approvalChain) {
      const { users } = approvalChain
      if (users) {
        setUserNameMap(
          users.reduce((acc: Record<string, string>, user: UserMeta) => {
            acc[user.id] = `${user.firstName} ${user.lastName}`
            return acc
          }, {})
        )
      }
    }

    if (history) {
      setHistory(history)
    }

    if (approvalBatch.status === 'approved') {
      setIsApproved(true)
    }
  }, [approvalBatch])

  const handleSubmit = async () => {
    try {
      const statusChange: ApprovalBatchStatusRequest = {
        approvalBatchId: approvalBatchId!,
        status: action as ApprovalBatchStatus,
      }
      const result = await submitStatusChange(statusChange)
      console.log('Status change submitted successfully')

      // Show success notification
      enqueueSnackbar('Approval action submitted successfully', { variant: 'success' })

      // Assuming result contains the updated approval batch
      if (result) {
        const { items, notes, approvalChain, history } = result

        if (items) {
          const planItem = items.find((item) => item.approvalType === 'plan')
          if (planItem?.plan) {
            setEmployeePlan(planItem.plan)
          }
        }

        if (notes) {
          setNotes(notes)
        }
        if (approvalChain) {
          const { users } = approvalChain
          if (users) {
            setUserNameMap(
              users.reduce((acc: Record<string, string>, user: UserMeta) => {
                acc[user.id] = `${user.firstName} ${user.lastName}`
                return acc
              }, {})
            )
          }
        }

        if (history) {
          setHistory(history)
        }

        // Update isApproved state if the new status is 'approved'
        if (action === 'approved') {
          setIsApproved(true)
        }
      }
    } catch (error) {
      console.error('Failed to submit status change:', error)
      // Show error notification
      enqueueSnackbar('Failed to submit approval action', { variant: 'error' })
    }
  }

  const renderLeftContent = () => {
    if (!approvalBatch || !approvalBatch.items) return null

    const approvalType = approvalBatch.items.find((item) => item.approvalType === ApprovalType.PLAN)
      ? ApprovalType.PLAN
      : approvalBatch.items[0].approvalType

    switch (approvalType) {
      case 'plan':
        if (!employeePlan) return null
        const expanded: ExpandedCompensationPlan = {
          plan: employeePlan!,
          period: approvalBatch.items[0].period!,
          components: approvalBatch.items[0].components || [],
          targets: approvalBatch.items[0].targets || [],
          metrics: approvalBatch.items[0].periodComponents || [],
        }
        return (
          <Grid spacing={3}>
            <CompensationSummaryCard
              name={planUser?.firstName + ' ' + planUser?.lastName}
              position={planUser?.position}
              effectiveDate={employeePlan?.effectiveDate}
              basePlus={employeePlan?.basePlus || false}
              variablePercent={employeePlan?.variablePercent || 0}
              baseSalary={employeePlan?.baseSalary || 0}
              variableCap={employeePlan?.variableCap || 0}
              periodProration={employeePlan?.periodProration || 1.0}
              color={theme.palette.primary.main}
              periodId={expanded.plan.periodId}
              period={expanded.period}
            />
            <Grid size={{ xs: 12 }} mt={3}>
              <PlanTargetsCard period={expanded.period} plan={expanded} />
            </Grid>

            <Grid size={{ xs: 12 }} mt={3}>
              <PlanAllocationsCard plan={expanded} ledgerAccounts={ledgerAccounts as LedgerAccount[] | null} />
            </Grid>
          </Grid>
        )
      case 'target':
        const groupedItems = approvalBatch.items.reduce(
          (acc, item) => {
            const orgName = item.organization?.name || 'Unknown Organization'
            if (!acc[orgName]) {
              acc[orgName] = []
            }
            acc[orgName].push(item)
            return acc
          },
          {} as Record<string, ExpandedApprovalItem[]>
        )
        return (
          <>
            {Object.keys(groupedItems).map((orgName) => (
              <TargetTable key={orgName} organizationName={orgName} items={groupedItems[orgName]} />
            ))}
          </>
        )
      case 'metric':
        const groupedMetrics = approvalBatch.items.reduce(
          (acc, item) => {
            const orgName = item.organization?.name || 'Unknown Organization'
            if (!acc[orgName]) {
              acc[orgName] = []
            }
            acc[orgName].push(item)
            return acc
          },
          {} as Record<string, ExpandedApprovalItem[]>
        )
        return (
          <>
            {Object.keys(groupedMetrics).map((orgName) => (
              <MetricTable key={orgName} organizationName={orgName} items={groupedMetrics[orgName]} />
            ))}
          </>
        )
      case 'accrual':
        return <AccrualTable items={approvalBatch.items} />
      default:
        return <div>Unknown approval type</div>
    }
  }

  if (isLoadingLedgerAccounts) {
    return <div>Loading ledger accounts...</div>
  }

  if (isErrorLedgerAccounts) {
    return <div>Error loading ledger accounts</div>
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid size={{ xs: 9 }}>{renderLeftContent()}</Grid>
        <Grid size={{ xs: 3 }}>
          <Box
            sx={{
              position: 'sticky',
              top: '20px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              height: isApproved ? '100vh' : '74vh',
            }}
          >
            {approvalBatchId && (
              <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                <ApprovalNotes
                  batchId={approvalBatchId}
                  notes={notes}
                  userNameMap={userNameMap}
                  history={history}
                  setNotes={setNotes}
                  isPlanApproval={isPlanApproval}
                  planUserId={planUserId}
                  planId={planId}
                  revision={revision}
                  isApproved={isApproved}
                  theme={theme}
                />
              </Box>
            )}
          </Box>
          {!isApproved && (
            <Box
              sx={{
                position: 'fixed',
                bottom: 0,
                backgroundColor: theme.palette.background.default,
                width: '25%',
              }}
            >
              <div style={{ marginRight: 100, marginBottom: 50 }}>
                <TextField
                  select
                  label="Action"
                  variant="standard"
                  value={action}
                  onChange={(e) => setAction(e.target.value)}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  <MenuItem value={'changes_requested'}>Request Changes</MenuItem>
                  <MenuItem value={'approved'}>Approve</MenuItem>
                  <MenuItem value={'rejected'}>Reject</MenuItem>
                </TextField>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={handleSubmit}
                  disabled={!action}
                >
                  Submit
                </Button>
              </div>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default EditApproval
