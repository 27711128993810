import { Card, CardContent, CircularProgress, Grid2 as Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CompPlanContent from 'components/CompPlanContent'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { usePlan } from 'hooks/usePlan'
import { useProfile } from 'hooks/useProfile'
import { FC, Suspense, useEffect, useState } from 'react'
import { ExpandedCompensationPlan } from 'types/comp-plan'
import { LedgerAccount } from 'types/ledger-account'

const COMP_PLAN_TITLE = 'Compensation Plan'

const CompPlan: FC = () => {
  const theme = useTheme()
  const { token } = useAuth()
  const { profile } = useProfile(token!)

  const [rootOrganizationId, setRootOrganizationId] = useState('')
  const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccount[] | undefined>([])
  const [profileInitialized, setProfileInitialized] = useState(false)
  const [plan, setPlan] = useState<ExpandedCompensationPlan | null>(null)

  const { compPlan, isLoading: planLoading } = usePlan(token!, profile?.user?.id, null, profileInitialized)

  const { ledgerAccounts: fetchedLedgerAccounts, isLoading: isLoadingLedgerAccounts } = useFetchLedgerAccounts(
    rootOrganizationId,
    token!,
    rootOrganizationId != null
  )

  const isLoading = planLoading || isLoadingLedgerAccounts

  useEffect(() => {
    if (profile) {
      setProfileInitialized(true)
    }
  }, [profile])

  useEffect(() => {
    if (compPlan) {
      setPlan(compPlan)
      setRootOrganizationId(compPlan.plan.rootOrganizationId)
    }
  }, [compPlan])

  useEffect(() => {
    if (fetchedLedgerAccounts) {
      setLedgerAccounts(fetchedLedgerAccounts)
    }
  }, [fetchedLedgerAccounts])

  if (isLoading) {
    return <LoadingView />
  }

  if (!plan?.plan) {
    return <NoPlanView />
  }

  return (
    <Suspense fallback={<CircularProgress color="primary" />}>
      <SimpleTitle title={COMP_PLAN_TITLE} />
      <CompPlanContent
        rootOrganizationId={rootOrganizationId}
        token={token!}
        user={profile?.user}
        compPlan={plan!}
        theme={theme}
        ledgerAccounts={ledgerAccounts}
      />
    </Suspense>
  )
}

const LoadingView: FC = () => (
  <Grid container spacing={12} style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
    <SimpleTitle title={COMP_PLAN_TITLE} />
    <CircularProgress color="primary" />
    Loading
  </Grid>
)

const NoPlanView: FC = () => (
  <Grid size={{ xs: 12 }}>
    <SimpleTitle title={COMP_PLAN_TITLE} />
    <Card>
      <CardContent>
        <Typography variant="h5">No valid compensation plan found</Typography>
        <Typography variant="body1">Please contact your manager or HR department for assistance.</Typography>
      </CardContent>
    </Card>
  </Grid>
)

export default CompPlan
