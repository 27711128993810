import { CloseCircleOutlined } from '@ant-design/icons'
import { Box, CircularProgress, Grid2 as Grid, IconButton, Tooltip } from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import SearchInput from 'components/SearchInput'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { useSearchComponents } from 'hooks/useComponents'
import useLocalStorage from 'hooks/useLocalStorage'
import { useProfile } from 'hooks/useProfile'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { ComponentQueryParams, ComponentSearchResponse } from 'types/components'
import { getOrganizationLabelMap } from 'types/user-profile'
import ComponentsTable from '../ComponentsTable'

const ManageComponents: FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>(
    'components-selected-organizations',
    []
  )
  const [page, setPage] = useLocalStorage<number>('components-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('components-rows-per-page', 25)
  const [sort, setSort] = useLocalStorage<string>('components-sort', 'label')
  const [order, setOrder] = useLocalStorage<string>('components-order', 'asc')

  const [organizationLabelMap, setOrganizationLabelMap] = useState<{ [key: string]: string }>({})
  const [isLoading, setIsLoading] = useState(false)
  const [components, setComponents] = useState<ComponentSearchResponse>({
    components: [],
    paginationInfo: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      perPage: 0,
    },
  })

  const { token } = useAuth()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)

  const queryParams: ComponentQueryParams = {
    organizationIds: selectedOrganizations,
    periodIds: [],
    userIds: [],
    searchTerm: searchTerm,
    pagination: {
      page: page + 1,
      perPage: rowsPerPage,
    },
    sort: [{ field: sort, order: order }],
  }

  const { componentSearchResult, isLoading: isLoadingComponents } = useSearchComponents(token!, queryParams)

  useEffect(() => {
    setIsLoading(isLoadingComponents || isLoadingProfile)
  }, [isLoadingComponents, isLoadingProfile])

  useEffect(() => {
    if (componentSearchResult) {
      console.log('Updating components state with new search results:', componentSearchResult.components.length)
      setComponents(componentSearchResult)
    }
  }, [componentSearchResult])

  useEffect(() => {
    if (profile) {
      const newOrganizationLabelMap = getOrganizationLabelMap(profile)
      setOrganizationLabelMap(newOrganizationLabelMap)
      console.log(organizationLabelMap)
    }
  }, [profile])

  useEffect(() => {}, [selectedOrganizations, searchTerm])

  // Handle sorting
  const handleSort = (property: string) => {
    const isAscending = sort === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setSort(property)
  }

  const handleSearch = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  const handleOrganizationChange = (newOrganizations: string[]) => {
    setSelectedOrganizations(newOrganizations)
    setPage(0)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Box>
      <SimpleTitle title="Components" />
      <Grid container spacing={4} mb={3}>
        <Grid size={{ xs: 3 }}>
          <SearchInput key="search-input" onSearch={handleSearch} initialValue={searchTerm} />
        </Grid>
        <Grid size={{ xs: 5 }}></Grid>
        <Grid size={{ xs: 3 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <MultiOrganizationSelector
            userProfile={profile}
            selectedOrganizationIds={selectedOrganizations}
            handleChange={handleOrganizationChange}
          />
        </Grid>
        <Grid size={{ xs: 1 }}>
          <Tooltip title="Reset Filters">
            <IconButton
              color="primary"
              onClick={() => {
                setSelectedOrganizations([])
                setSearchTerm('')
              }}
              sx={{ mt: 2 }}
            >
              <CloseCircleOutlined />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <ComponentsTable
          key="components-table"
          components={components.components}
          page={page}
          rowsPerPage={rowsPerPage}
          sort={sort}
          order={order}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSort={handleSort}
          totalItems={components.paginationInfo.totalItems}
        />
      )}
    </Box>
  )
}

export default ManageComponents
